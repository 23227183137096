import { HttpClient, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { last, Observable } from 'rxjs';
import { AutoComplete, LookUp } from '@pages/map/interfaces/HEREAPI';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';

@Injectable()
export class HereApiService {
  apikey = 'wyBnr85mHwleRwtLqvj5fwZ9_g9JnCovZMinW7FxHhs'; // where to store it
  httpClient = inject(HttpClient);
  jsonP = inject(JsonpClientBackend);

  mapUIControls = inject(HereMapsUiControlsService);

  lat = '';
  lng = '';

  private readonly autoCompleteURL = 'https://autocomplete.search.hereapi.com/v1/autocomplete';
  private readonly autoSuggestURL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
  private readonly lookupURL = 'https://lookup.search.hereapi.com/v1/lookup';

  private countryCodes =
    'countryCode:AUT,BEL,BGR,HRV,CYP,CZE,DNK,EST,FIN,FRA,DEU,GRC,HUN,IRL,ITA,LVA,LTU,LUX,MLT,NLD,POL,PRT,ROU,SVK,SVN,ESP,SWE,GBR,ALB,AND,ARM,AZE,BLR,BIH,GEO,ISL,IRL,KAZ,LIE,MDA,MCO,MNE,MKD,NOR,RUS,SMR,SRB,CHE,TUR,VAT,UKR';

  constructor() {}

  formatMapCenterToString() {
    this.lat = this.mapUIControls.mapCenter.lat.toString();
    this.lng = this.mapUIControls.mapCenter.lng.toString();
  }

  getAutoSuggesteParams(searchText: string): HttpParams {
    this.formatMapCenterToString();
    return new HttpParams()
      .set('apiKey', this.apikey)
      .set('at', this.lat + ',' + this.lng)
      .set('in', this.countryCodes)
      .set('lang', 'en-US')
      .set('q', searchText)
      .set('limit', 5);
  }

  getAutoSuggestResults(searchText: string): Observable<AutoComplete> {
    return this.httpClient.jsonp(
      this.autoSuggestURL + '?' + this.getAutoSuggesteParams(searchText).toString(),
      'callback',
    ) as any;
  }

  getLookUpParams(id: string) {
    return new HttpParams().set('apiKey', this.apikey).set('id', id);
  }

  getLookUResults(id: string): Observable<LookUp> {
    return this.httpClient.jsonp(this.lookupURL + '?' + this.getLookUpParams(id).toString(), 'callback') as any;
  }
}
