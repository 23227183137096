import { ChangeDetectionStrategy, Component, computed, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { combineLatest, map, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DepartureSchedulingApi } from '../../services/departure-scheduling/departure-scheduling.api';
import { PermissionsService } from '../../../core/services/auth/permissions.service';
import { FeatureToggleService } from '../../../core/services/auth/feature-toggle.service';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
  imports: [TranslatePipe, TranslateModule, RouterModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: '[app-stencil-sidemenu]',
  templateUrl: './stencil-sidemenu.component.html',
  styleUrls: ['./stencil-sidemenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StencilSidemenuComponent {
  private permissionService = inject(PermissionsService);
  private featureToggle = inject(FeatureToggleService);
  private departureSchedulingApi = inject(DepartureSchedulingApi);
  private router = inject(Router);

  envName = environment.name;

  hasEquipmentWithDepartureScheduling = this.departureSchedulingApi.hasEquipmentWithDepartureScheduling;

  /*
                  *************** IMPORTANT ***************
     BEV CONTROL MEANS = functional permission ViewChargingDashboard
     Business uses Bev Control word, which has no meaning to us (as developers)

  */

  viewOverviewLink: boolean = false;

  ViewChargingDashboard$: Observable<boolean> = combineLatest([
    this.permissionService.user_has_FunctionalPermission(['ViewChargingDashboard']),
    this.permissionService.user_has_userPermission(['ViewChargingDashboard']),
  ]).pipe(
    map(([hasfuncPermission, hasuserPermission]) => hasfuncPermission && hasuserPermission),
    tap((viewdashboard) => (viewdashboard ? '' : (this.viewOverviewLink = true))),
  );

  viewDepartureSchedulingFt$ = this.featureToggle.isFeatureOn(['ViewDepartureScheduling']);

  viewPublicCharging$ = this.featureToggle.isFeatureOn(['ViewPublicCharging']);

  viewAdmin$ = this.featureToggle.isFeatureOn(['Admin']);

  viewMap$ = this.featureToggle.isFeatureOn(['ViewMap']);

  viewRTMChargingSession$ = this.featureToggle.isFeatureOn(['RTMChargingSession']);

  viewChargingSettings$: Observable<boolean> = combineLatest([
    this.permissionService.user_has_FunctionalPermission(['ViewChargingDashboard']),
    this.permissionService.user_has_userPermission(['ViewChargingSettings']),
  ]).pipe(map(([hasfuncPermission, hasuserPermission]) => hasfuncPermission && hasuserPermission));

  viewDepartureScheduling = computed(() => {
    return (
      this.hasEquipmentWithDepartureScheduling() &&
      this.permissionService.user_has_userPermission(['ViewDepartureScheduling'])
    );
  });

  // viewDepartureScheduling$: Observable<boolean> = combineLatest([
  //   this.hasEquipmentWithDepartureScheduling,
  //   this.permissionService.user_has_userPermission(['ViewDepartureScheduling']),
  // ]).pipe(
  //   map(
  //     ([hasEquipmentWithDepartureScheduling, hasuserPermission]) =>
  //       hasEquipmentWithDepartureScheduling && hasuserPermission,
  //   ),
  // );

  isActived() {
    return this.router.url.includes('charging-management') || this.router.url.includes('onboarding') ? 'active' : '';
  }
}
