import { Component, EventEmitter, Input, Output, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [CommonModule, TranslatePipe, TranslateModule],
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationPopupComponent implements OnInit {
  @Input() message: any;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  notificationUrl: string;
  constructor() {}

  ngOnInit() {
    if (this.message?.data?.service.toLowerCase().includes('departure scheduling')) {
      this.notificationUrl = self.location.origin + '/departure-scheduling';
    }
  }

  closeModal() {
    this.closePopup.emit();
  }
}
