import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { IUserAuthorizationLevel, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  currentPermissions$ = this.userService.userAuthorizationLevel$;

  constructor(private userService: UserService) {}

  user_has_userPermission(requiredPermission: string[]): Observable<boolean> {
    return this.currentPermissions$.pipe(
      filter(({ initialized }) => initialized == true), // Wait for an initialized object
      map((data: IUserAuthorizationLevel) =>
        data.userPermissions?.some((userPermissions) => requiredPermission.includes(userPermissions)) ? true : false,
      ),
    );
  }

  user_has_FunctionalPermission(requiredPermission: string[]): Observable<boolean> {
    return this.currentPermissions$.pipe(
      filter(({ initialized }) => initialized == true), // Wait for an initialized object
      map((data: IUserAuthorizationLevel) =>
        data.functionalPermissions?.some((funcPermissions) => requiredPermission.includes(funcPermissions))
          ? true
          : false,
      ),
    );
  }

  user_has_Roles(requiredRoles: string[]) {
    return this.currentPermissions$.pipe(
      filter(({ initialized }) => initialized == true), // Wait for an initialized object
      map((data: IUserAuthorizationLevel) =>
        data.roles?.some((roles) => requiredRoles.includes(roles)) ? true : false,
      ),
    );
  }

  getFunctionalPermissions() {
    return this.userService.userData()?.functionalPermissions;
  }

  getUserPermissions() {
    return this.userService.userData()?.userPermissions;
  }

  getUserRoles() {
    return this.userService.userData()?.roles;
  }

  userHasFunctionalPermissions(functionalPermissions: number[], matchAll: boolean = true) {
    const fp = this.getFunctionalPermissions();
    return matchAll
      ? fp?.every((fp: any) => functionalPermissions.includes(fp))
      : fp?.some((fp: any) => functionalPermissions.includes(fp));
  }

  userHasUserPermissions(userPermissions: number[], matchAll: boolean = true) {
    const up = this.getUserPermissions();
    return matchAll
      ? up?.every((up: any) => userPermissions.includes(up))
      : up?.some((up: any) => userPermissions.includes(up));
  }
}
