import { HttpClient } from '@angular/common/http';
import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { DatadogMetricLoggerService } from '@core/services/datadog-metric-logger.service';
import { environment } from '@environments/environment';
import { ClusteredChargePoints } from '@pages/map/interfaces/chargePointClustering';
import { chargePointDetails, IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { ChargePointApiService } from '@pages/map/services/charge-point-api.service';
import { ClusteringService } from '@pages/map/services/clustering.service';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { MapUiStateService } from '@pages/map/services/map-ui-state.service';
import { MarkerService } from '@pages/map/services/marker.service';
import { isPartner } from '@pages/map/utils/partner-filtering';
import { first } from 'rxjs';

export const InitialMapPosition = {
  lat: '59.165692143635496',
  long: '17.61991628465645',
  radius: 400,
};

export type chargingStationType = 'Truck Charging' | 'Car Charging';

@Injectable()
export class ChargePointService {
  private markerService = inject(MarkerService);
  private chargePointAPI = inject(ChargePointApiService);
  private mapUIControls = inject(HereMapsUiControlsService);
  private clusteringService = inject(ClusteringService);

  truckChargers = signal<IChargePointv2[]>([]);
  carChargers = signal<IChargePointv2[]>([]);
  displayedChargePoints = signal<IChargePointv2[]>([]);

  selectedChargingStationType = signal<chargingStationType>('Truck Charging');

  updateChargerSelection(type: chargingStationType) {
    this.selectedChargingStationType.set(type);
  }

  visibleChargePoints = signal<IChargePointv2[]>([]);
  visibleMarkers = signal<any>([]);

  calculateVisibleChargePoints() {
    const a = this.displayedChargePoints().filter((cpo) => {
      return this.mapUIControls.boundingBox?.containsPoint({
        lat: cpo.coordinates.latitude,
        lng: cpo.coordinates.longitude,
      });
    });

    this.visibleChargePoints.set(a);
  }

  constructor() {
    effect(() => {
      if (this.selectedChargingStationType() === 'Truck Charging') {
        this.displayedChargePoints.set(this.truckChargers());
        this.calculateVisibleChargePoints();
      } else {
        this.displayedChargePoints.set(this.carChargers());
        this.calculateVisibleChargePoints();
      }
      this.selectedChargingStationType();
    });
  }

  mapViewChanged() {
    this.calculateVisibleChargePoints();
    this.clusteringService.calculateVisibleClusters();

    if (false) {
      //temp demo
      console.log('using clusterEndPoint');
      // this.clusteringService.get3ClusteredChargePoints();
      // this.getClusteredChargePoints();
    } else {
      console.log('Using list Endpoint');
      this.getChargePoints();
    }
  }

  getChargePoints() {
    if (this.carChargers().length === 0) {
      this.chargePointAPI
        .getChargePointListForCars()
        .pipe(first())
        .subscribe({
          next: (chargePoints: IChargePointv2[]) => {
            const sortedCPOS = this.sortChargePoints(chargePoints);
            this.carChargers.set(sortedCPOS);
            this.markerService.createClusterforCars(chargePoints);
          },
          error: () => {},
          complete: () => {},
        });

      this.chargePointAPI
        .getChargePointListForTrucks()
        .pipe(first())
        .subscribe({
          next: (chargePoints: IChargePointv2[]) => {
            const sortedCPOS = this.sortChargePoints(chargePoints);
            this.truckChargers.set(sortedCPOS);
            this.markerService.createClusterForTrucks(chargePoints);

            // set truck as default
            this.clusteringService.switchLayer('Truck Charging');
          },
          error: () => {},
          complete: () => {},
        });
    }
  }

  sortChargePoints(chargePoints: IChargePointv2[]): IChargePointv2[] {
    return chargePoints.sort(
      (a, b) => this.compareCPOsPartner(a, b) || this.compareCPOsGrade(a, b) || this.compareCPOsMaxPower(a, b),
    );
  }

  compareCPOsPartner(first: IChargePointv2, second: IChargePointv2): number {
    if (isPartner(first.name) === isPartner(second.name)) {
      return 0;
    } else {
      return isPartner(first.name) && !isPartner(second.name) ? -1 : 1;
    }
  }

  compareCPOsGrade(first: IChargePointv2, second: IChargePointv2): number {
    if (first.truckChargingGrade === second.truckChargingGrade) {
      return 0;
    } else {
      return first.truckChargingGrade < second.truckChargingGrade ? 1 : -1;
    }
  }

  compareCPOsMaxPower(first: IChargePointv2, second: IChargePointv2): number {
    if (first.maxPower === second.maxPower) {
      return 0;
    } else {
      return first.maxPower < second.maxPower ? 1 : -1;
    }
  }

  getChargePointDetail(chargePointId: string) {
    return this.chargePointAPI.getChargePointDetail(chargePointId);
  }
}
