import { Injectable, inject } from '@angular/core';
import { DatadogMetricLoggerService } from '@core/services/datadog-metric-logger.service';

import { IChargePointv2 } from '@pages/map/interfaces/chargepoints';
import { MapUiStateService } from './map-ui-state.service';
import { ClusteredChargePoints } from '@pages/map/interfaces/chargePointClustering';
import { HereMapsUiControlsService } from '@pages/map/services/here-maps-ui-controls.service';
import { Position } from '@pages/map/interfaces/HEREAPI';
import {
  customLocationPinSVG,
  truck_charging,
  truck_charging_hover,
  unspecified_charging,
  unspecified_charging_hover,
} from '@pages/map/assets/markers';
import { ClusteringService } from '@pages/map/services/clustering.service';

type markerData = {
  chargePointInfo: IChargePointv2;
  hoverIcon: H.map.Icon;
};

@Injectable()
export class MarkerService {
  datadogLogger = inject(DatadogMetricLoggerService);
  private mapUIStateService = inject(MapUiStateService);
  private mapUIControls = inject(HereMapsUiControlsService);
  private clusteringService = inject(ClusteringService);

  currentMarkers = this.mapUIStateService.currentMarkers;

  truck_charging_icon = new H.map.Icon(truck_charging);
  truck_charging_icon_hover = new H.map.Icon(truck_charging_hover);
  customLocationPin = new H.map.Icon(customLocationPinSVG);
  unspecified_icon = new H.map.Icon(unspecified_charging);
  unspecified_icon_hover = new H.map.Icon(unspecified_charging_hover);

  group = new H.map.Group();

  hoverSupport = this.mapUIStateService.hoverFeatureAvailable;

  highlightedMarker: H.map.Marker;

  constructor() {
    this.group = new H.map.Group();
  }

  clearMarkers() {
    const t1 = performance.now();
    if (this.currentMarkers.length) {
      this.currentMarkers.forEach((marker) => {
        this.group.removeObject(marker);
      });
      this.currentMarkers = [];
    }
    const t2 = performance.now();
    this.datadogLogger.costOfRemovingMarkers({
      amountOfMarkers: this.currentMarkers.length,
      duration: t2 - t1,
    });
  }

  createMarkers(chargePoints: IChargePointv2[]) {
    const t1 = performance.now();
    this.clearMarkers();
    chargePoints.forEach((chargePoint) => {
      let markerData: markerData = {
        chargePointInfo: chargePoint,
        hoverIcon: this.setHoverIcon(chargePoint),
      };

      const marker = new H.map.Marker(
        {
          lat: chargePoint.coordinates.latitude,
          lng: chargePoint.coordinates.longitude,
        },
        {
          icon: this.setIconType(chargePoint),
          data: markerData,
          volatility: true,
          zIndex: this.setZindexOfMarker(chargePoint),
          min: 10,
        },
      );

      if (this.hoverSupport) {
        marker.addEventListener('pointerenter', () => {
          this.highlightChargePointMarker(marker.getData().chargePointInfo);
          this.highlightChargePointInTheList(marker.getData().chargePointInfo);
        });

        marker.addEventListener('pointerleave', () => {
          this.revertHighlightedChargePointIntheList();
          this.revertHighlightedMarker(marker.getData().chargePointInfo);
        });
      }

      this.currentMarkers.push(marker);
      this.group.addObject(marker);
    });

    this.mapUIControls.mapInstance.addObject(this.group);
    const t2 = performance.now();
    this.datadogLogger.costOfAddingMarkers({
      amountOfMarkers: chargePoints.length,
      duration: t2 - t1,
    });
  }

  setZindexOfMarker(chargePoint: IChargePointv2): number {
    return chargePoint.truckChargingGrade === 4 ? 2 : 1;
  }

  setIconType(chargePoint: IChargePointv2): H.map.Icon {
    const icon = chargePoint.truckChargingGrade === 4 ? this.truck_charging_icon : this.unspecified_icon;
    return icon;
  }

  setHoverIcon(chargePoint: IChargePointv2) {
    const icon = chargePoint.truckChargingGrade === 4 ? this.truck_charging_icon_hover : this.unspecified_icon_hover;
    return icon;
  }

  handlePointerTap(event: any) {
    if (event.target instanceof H.map.Marker) {
      let markerData = event.target.getData() as markerData;

      this.mapUIStateService.selectedChargePoint.set(markerData.chargePointInfo);
      this.mapUIStateService.showChargePointDetails.set(true);
      this.mapUIStateService.hideFilterMenu();
      this.zoomIntoChargePoint(markerData.chargePointInfo);
    }
  }
  resetPreviouslySelectedChargePointMarkerIcon() {}

  attachMapListener() {
    // this.mapUIControls.mapInstance.addEventListener(
    //   'tap',
    //   (event: { target: any }) => {
    //     this.handlePointerTap(event);
    //   },
    //   false,
    // );
  }

  zoomIntoChargePoint(chargePoint: IChargePointv2) {
    this.mapUIControls.navigateToPoint(
      {
        lat: chargePoint.coordinates.latitude,
        lng: chargePoint.coordinates.longitude,
      },
      16,
    );
  }

  // setMarkerRenderingLogic(chargePoints: ClusteredChargePoints[] | IChargePointv2[]) {
  //   this.clusteringService.createCluster(chargePoints as IChargePointv2[]);
  // }

  createClusterForTrucks(chargePoints: IChargePointv2[]) {
    this.clusteringService.createTruckCluster(chargePoints);
  }

  createClusterforCars(chargePoints: IChargePointv2[]) {
    this.clusteringService.createCarCluster(chargePoints);
  }

  highlightChargePointMarker(chargePoint: IChargePointv2) {
    const marker = this.currentMarkers.find((cp) => {
      let markerData = cp.getData() as markerData;
      return markerData.chargePointInfo.chargePointId == chargePoint.chargePointId;
    });

    if (marker) {
      marker.setIcon(this.setHoverIcon(chargePoint));
      this.highlightedMarker = marker;
    }
  }

  revertHighlightedMarker(chargePoint: IChargePointv2) {
    // this.highlightedMarker.setIcon(this.setIconType(chargePoint));
  }

  highlightChargePointInTheList(chargePoint: IChargePointv2) {
    this.mapUIStateService.hoveredChargePoint.set(chargePoint);
  }

  revertHighlightedChargePointIntheList() {
    // this.mapUIStateService.hoveredChargePoint.set(undefined);
  }

  addMarkerToSearchedLocation(position: Position) {
    const marker = new H.map.Marker(
      {
        lat: position.lat,
        lng: position.lng,
      },
      {
        icon: this.customLocationPin,
        data: '',
        zIndex: 3,
      },
    );
    this.mapUIControls.mapInstance.addObject(marker);

    setTimeout(() => {
      this.mapUIControls.mapInstance.removeObject(marker);
    }, 14000);
  }
}
